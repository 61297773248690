@font-face {
  font-family: 'ApexLight';
  src: url('./apex_light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ApexNormal';
  src: url('./apex_medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ApexBlack';
  src: url('./apex_black.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'ApexBold';
  src: url('./apex_bold.otf') format('opentype');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'ApexRegular';
  src: url('./apex_regular.otf') format('opentype');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaBold';
  src: url('./futura_bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'GelionLight';
  src: url('./gelion-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GelionRegular';
  src: url('./gelion-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'GelionMedium';
  src: url('./gelion-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'GelionBold';
  src: url('./gelion_bold.ttf') format('truetype');
  font-weight: bolder;
  font-style: normal;
}
